import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const API_BASE_URL = 'https://shopifybackend.mustafaemresahin.com/api';

const ReceiveMasterPO = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [masterPO, setMasterPO] = useState(null);
  const [receivedQuantities, setReceivedQuantities] = useState({});
  const [productDifferences, setProductDifferences] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Fix success message color
  const [loading, setLoading] = useState(false); // Loading state for refresh

  // Function to fetch MPO data with useCallback to prevent re-creation
  const fetchMasterPO = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/master-pos/${id}/received`);
      setMasterPO(response.data.masterPO);
      setProductDifferences(response.data.productDifferences);

      // Initialize received quantities using productId + orderedQuantity as key
      const initialQuantities = {};
      response.data.productDifferences.forEach((product) => {
        const uniqueKey = `${product.productId}-${product.orderedQuantity}`;
        initialQuantities[uniqueKey] = product.receivedQuantity || ''; // Ensure separate values
      });

      setReceivedQuantities(initialQuantities);
    } catch (error) {
      console.error('Error fetching MPO:', error);
    }
    setLoading(false);
  }, [id]); // Memoize using id as a dependency

  useEffect(() => {
    fetchMasterPO();
  }, [fetchMasterPO]); // Now ESLint won't warn about missing dependency

  // Handle changes in received quantities per row
  const handleQuantityChange = (productId, orderedQuantity, value) => {
    const uniqueKey = `${productId}-${orderedQuantity}`;
    setReceivedQuantities((prevState) => ({
      ...prevState,
      [uniqueKey]: value, // Ensure only the specific product row updates
    }));
  };

  // Save received quantities
  const handleSaveReceived = async () => {
    // Ensure no empty fields
    if (Object.values(receivedQuantities).some(q => q === '')) {
      setSnackbarMessage('All received quantities must be filled out');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    try {
      await axios.put(`${API_BASE_URL}/master-pos/${id}/receive`, {
        receivedProducts: Object.entries(receivedQuantities).map(([uniqueKey, receivedQuantity]) => {
          const [productId, orderedQuantity] = uniqueKey.split('-');
          return {
            productId: String(productId), // Ensure productId is string
            orderedQuantity: Number(orderedQuantity),
            receivedQuantity: Number(receivedQuantity),
          };
        }),
      });

      setSnackbarMessage('Received quantities updated successfully');
      setSnackbarSeverity('success'); // Fix success message color
      setOpenSnackbar(true);

      // Refresh table with updated data
      fetchMasterPO();
    } catch (error) {
      console.error('Error updating received quantities:', error);
      setSnackbarMessage('Failed to update received quantities');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  if (!masterPO || loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        <CircularProgress size={60} />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(`/master-po/${id}`)}
        sx={{ mb: 2 }}
      >
        Go Back
      </Button>

      <Typography variant="h4" gutterBottom>
        Receive Order: {masterPO.poName}
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product Name</TableCell>
              <TableCell align="right">Ordered Quantity</TableCell>
              <TableCell align="right">Received Quantity</TableCell>
              <TableCell align="right">Difference</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productDifferences.map((product) => {
              const uniqueKey = `${product.productId}-${product.orderedQuantity}`;
              const difference = receivedQuantities[uniqueKey] - product.orderedQuantity;
              let differenceColor = 'inherit';

              if (difference < 0) {
                differenceColor = 'red'; // Shortage
              } else if (difference > 0) {
                differenceColor = 'green'; // Over-received
              }

              return (
                <TableRow key={uniqueKey}>
                  <TableCell>{product.productTitle}</TableCell>
                  <TableCell align="right">{product.orderedQuantity}</TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      value={receivedQuantities[uniqueKey] || ''}
                      onChange={(e) => handleQuantityChange(product.productId, product.orderedQuantity, e.target.value)}
                      inputProps={{ min: 0 }}
                      required
                    />
                  </TableCell>
                  <TableCell align="right" style={{ color: differenceColor }}>
                    {difference > 0 ? `+${difference}` : difference}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Button
        variant="contained"
        color="primary"
        onClick={handleSaveReceived}
        sx={{ mt: 3 }}
      >
        Save Received Quantities
      </Button>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ReceiveMasterPO;
