import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Route, Routes, Link, useLocation } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ListIcon from '@mui/icons-material/List';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import AssignmentIcon from '@mui/icons-material/Assignment'; // Icon for POs
import InventoryIcon from '@mui/icons-material/Inventory'; // Import the Inventory icon
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial'; // Import an icon for Master POs
import HelpIcon from '@mui/icons-material/Help'; // Icon for documentation link

// Importing components and pages
import Dashboard from './components/dashboard/Dashboard';
import GraphsPage from './components/graphs-page/GraphsPage';
import ProductDetailPage from './components/product-detail-page/ProductDetailPage';
import TodaysSalesProductDetailPage from './components/todays-sales-page/TodaysSalesProductDetailPage';
import ProductList from './components/product-list-page/ProductList';
import TodaysGraphsPage from './components/todays-sales-page/TodaysGraphsPage';
import POList from './components/po-list-page/POList';
import POCreateStep1 from './components/po-create-page/POCreateStep1';
import PODetails from './components/po-details-page/PODetails';
import POEdit from './components/po-edit-page/POEdit';
import CreateMasterPO from './components/master-po/CreateMasterPO';
import MasterPODetails from './components/master-po/MasterPODetails';
// import POListPage from './pages/POListPage';
import MasterPOListPage from './pages/MasterPOListPage';
import InventoryCount from './components/inventory-count/InventoryCount'; // Import Inventory Count component
import InventoryCountSessions from './components/inventory-count/InventoryCountSessions'; // Import Inventory Count Sessions
import ProductsWithoutBarcode from './components/inventory-count/ProductsWithoutBarcode';

import ReceiveMasterPO from './components/master-po/ReceiveMasterPO'; // Import the new page

const theme = createTheme({
  palette: {
    primary: { main: '#1976d2' },
    secondary: { main: '#ff9800' },
    background: { default: '#f5f5f5' },
  },
  typography: { fontFamily: 'Roboto, sans-serif' },
});

const drawerWidth = 250;

function App() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation(); // To determine the current route for active styling

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Toolbar sx={{ minHeight: 500, zIndex: 1 }}>
        {/* Sidebar header with logo or title */}
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: '-60px', marginLeft: '50px' }}>
          {/* You can place your logo or title here */}
        </Typography>
      </Toolbar>
      <List style={{ flexGrow: 1 }}>
        {[
          { text: 'Dashboard', icon: <DashboardIcon />, path: '/' },
          { text: 'Product List', icon: <ListIcon />, path: '/product-list' },
          { text: 'Graphs', icon: <ShowChartIcon />, path: '/graphs' },
          { text: 'Purchase Lists', icon: <AssignmentIcon />, path: '/po-list' },
          { text: 'Purchase Orders', icon: <FolderSpecialIcon />, path: '/master-pos' },
          { text: 'Inventory Count', icon: <InventoryIcon />, path: '/inventory-count' },
        ].map((item) => (
          <ListItem
            button
            component={Link}
            to={item.path}
            key={item.text}
            sx={{
              backgroundColor: location.pathname === item.path ? '#e0e0e0' : 'inherit',
              '&:hover': { backgroundColor: '#e0e0e0' },
            }}
          >
            <ListItemIcon sx={{ color: location.pathname === item.path ? '#1976d2' : 'inherit' }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
      <Divider /> {/* Divider to separate the main menu items from the documentation link */}
      {/* Documentation link at the bottom */}
      <List>
        <ListItem
          button
          component="a"
          href="https://shopifyappdocumentation.mustafaemresahin.com/"
          key="Documentation"
          sx={{
            '&:hover': { backgroundColor: '#e0e0e0' },
          }}
          target="_blank"
        >
          <ListItemIcon sx={{ color: 'inherit' }}>
            <HelpIcon />
          </ListItemIcon>
          <ListItemText primary="Documentation" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: 'flex' }}>
        {/* AppBar */}
        <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1, backgroundColor: '#1976d2', height: 50 }}>
          <Toolbar sx={{ paddingLeft: 1, paddingRight: 1 }}>
            <Typography variant="h6" sx={{ fontSize: '1.5rem', flexGrow: 1, paddingBottom: '50px', marginLeft: '-10px' }}>
              Shopify Inventory Tracker
            </Typography>
          </Toolbar>
        </AppBar>

        {/* Drawer for desktop and mobile views */}
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', backgroundColor: 'lightgray', borderRight: 'none' },
            display: { xs: 'none', sm: 'block' },
          }}
          open
        >
          {drawer}
        </Drawer>

        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          sx={{
            display: { xs: 'block', sm: 'none' },
            [`& .MuiDrawer-paper`]: { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'lightgray', borderRight: 'none' },
          }}
          ModalProps={{ keepMounted: true }}
        >
          {drawer}
        </Drawer>

        {/* Main content area */}
        <Box component="main" sx={{ flexGrow: 1, p: 5, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
          <Toolbar sx={{ minHeight: 64 }} />
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/product-list" element={<ProductList />} />
            <Route path="/graphs" element={<GraphsPage />} />
            <Route path="/todays-graphs" element={<TodaysGraphsPage />} />
            <Route path="/product/:id" element={<ProductDetailPage />} />
            <Route path="/todays-graphs/product/:id" element={<TodaysSalesProductDetailPage />} />
            <Route path="/po-list" element={<POList />} />
            <Route path="/po-create-step1" element={<POCreateStep1 />} />
            <Route path="/po/:id" element={<PODetails />} />
            <Route path="/po-edit/:id" element={<POEdit />} />
            <Route path="/create-master-po" element={<CreateMasterPO />} />
            <Route path="/master-po/:id" element={<MasterPODetails />} />
            <Route path="/master-pos" element={<MasterPOListPage />} />
            <Route path="/inventory-count" element={<InventoryCountSessions />} />
            <Route path="/inventory-count/:sessionId" element={<InventoryCount />} />
            <Route path="/inventory-count/barcodes" element={<ProductsWithoutBarcode />} />
            <Route path="/master-po/:id/receive" element={<ReceiveMasterPO />} /> {/* New Receive Orders Page */}
          </Routes>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default App;
